







































































import Vue, { PropOptions } from 'vue';
import { ICompositionRoot } from '../../src/ICompositionRoot';
import { Category } from '../../../shared/src/entities/Category';
import { Product } from '../../../shared/src/entities/Product';
import { ImageRef } from '../../../shared/src/entities/ImageRef';
import { IProductDataservice } from '../../../shared/src/dataservices/IProductDataservice';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import { ISettingsDataservice } from '../../../shared/src/dataservices/ISettingsDataservice';
import { IMetadataService } from '../../../shared/src/services/IMetadataService';
import { ProductStatus } from '../../../shared/src/entities/ProductStatus';

export default Vue.extend({
  inject: {
    categoryDataservice: 'categoryDataservice',
    productDataservice: 'productDataservice',
    settingsDataservice: 'settingsDataservice',
    metadataService: 'metadataService'
  },
  watch: {
    pagename: {
      immediate: true,
      handler(newValue): void {
        return ((this as any).metadataService as IMetadataService).setTitle(newValue);
      }
    },
    description: {
      immediate: true,
      handler(newValue): void {
        return ((this as any).metadataService as IMetadataService).setDescription(newValue);
      }
    }
  },
  methods: {
    isProductSold() {
      if (!this.product) {
        return false;
      }
      return this.product.status === ProductStatus.SOLD;
    }
  },
  computed: {
    product(): Product | undefined {
      // TODO: Remove cast when this is fixed: https://github.com/vuejs/vue/issues/8969
      // Maybe combined with as PropOptions<IProductDataservice> on the injected values
      return ((this as any).productDataservice as IProductDataservice).getProductById(this.$route.params.productId);
    },
    pagename(): string {
      if (!this.product) {
        return ((this as any).settingsDataservice as ISettingsDataservice).shopName;
      }

      return this.product.name + ' - ' + ((this as any).settingsDataservice as ISettingsDataservice).shopName;
    },
    description(): string {
      if (!this.product) {
        return '';
      }
      return this.product.description;
    },
    category(): Category | undefined {
      if (!this.product || !this.product.categoryId) {
        return undefined;
      }
      return ((this as any).categoryDataservice as ICategoryDataservice).getCategoryById(this.product.categoryId);
    },
    images(): ImageRef[] {
      if (!this.product) {
        return [];
      }

      return Array.from(this.product.images, ([key, val]) => {
          return val;
      }).filter( (imageRef) => imageRef.mediumImagedownloadUrl);
    },
    mailto(): string {
      return '';
      // TODO return 'mailto:?subject=' + this.product.name +
      // '&body=<a href="Hej!%0A%0ASe hvad jeg har fundet til dig:%0D%0A' + window.location.href;
    },
    breadcrumbs(): any[] {
      if (!this.product || !this.category) {
         return [
          {
            text: 'Forside',
            disabled: false,
            href: '/',
          }];
      }

      return [
        {
          text: 'Forside',
          disabled: false,
          to: {name: 'home'},
          exact: true
        },
        {
          text: this.category.name,
          disabled: false,
          exact: true,
          to: { path: '/' + this.category.nameUriformatted }
        },
        {
          text: this.product.name,
          disabled: true,
        },
      ];
    }
  },
});
